

import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator'
import path from 'path'

@Component({})
export default class AudioPlayer extends Vue {
  @Prop() private audioUrl!: string

  @Watch('audioUrl')
  onAudioUrlChange(newAudioUrl: string, oldAudioUrl: string) {
    this.audioUrl = newAudioUrl
  }

  mimeType(source: string) {
    if (source) {
      return `audio/${path.extname(source).substring(1)}`
    }
    return ''
  }
}
